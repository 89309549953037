import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';

export const getOutcomes = createAction('outcomes/GET_INCOMES');

const statsSlice = createSlice({
  initialState: initialStore.stats,
  name: 'stats',
  reducers: {
    updateStats: (state, { payload }) => {
      state.data = payload;
    }
  }
});

export const { updateStats } = statsSlice.actions;

export const { reducer } = statsSlice;

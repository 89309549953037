import { App } from 'antd';
import { createContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';

import { updateIncomeCount } from '#store/incomesPage';
import { updateOutcomeCount } from '#store/outcomePage';
import { updateStats } from '#store/stats';
import { getTokenFromCookies } from '#utils/cookies-actions';

export const WebSocketContext = createContext(null);
const URL = process.env.REACT_APP_BASE_API_URL?.split('/api/admin/')[0];

const WebSocketProvider = ({ children }) => {
  const token = getTokenFromCookies();
  const dispatch = useDispatch();
  const { notification } = App.useApp();
  const { t } = useTranslation();

  const socketRef = useRef(null);

  const isMutedRef = useRef(localStorage.getItem('isMuted') === 'true');
  const [isMuted, setIsMuted] = useState(isMutedRef.current);

  useEffect(() => {
    localStorage.setItem('isMuted', isMuted.toString());
    isMutedRef.current = isMuted;
  }, [isMuted]);

  let audioContext = useRef(
    new (window.AudioContext || window.webkitAudioContext)()
  ).current;

  useEffect(() => {
    function unlockAudioContext() {
      if (audioContext.state === 'suspended') {
        audioContext.resume();
      }
      document.removeEventListener('click', unlockAudioContext);
      document.removeEventListener('keydown', unlockAudioContext);
    }

    document.addEventListener('click', unlockAudioContext);
    document.addEventListener('keydown', unlockAudioContext);

    return () => {
      document.removeEventListener('click', unlockAudioContext);
      document.removeEventListener('keydown', unlockAudioContext);
    };
  }, [audioContext]);

  useEffect(() => {
    if (socketRef.current) return;

    const socket = io(URL, {
      extraHeaders: { Authorization: `${token}` }
    });

    socket.connect();
    socket.on('connect', () => console.log('Connected to WebSocket'));
    socket.on('disconnect', () => console.log('Disconnected from WebSocket'));

    socket.on('stats', (data) => dispatch(updateStats(data)));

    const playNotificationSound = async () => {
      if (isMutedRef.current) return;

      try {
        const response = await fetch('/notification.mp3');
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContext.destination);
        source?.start?.(0);
      } catch (error) {
        console.error('Audio playback failed:', error);
      }
    };

    socket.on('income-order:amount', (data) => {
      playNotificationSound();
      dispatch(updateIncomeCount(data));
    });

    socket.on('outcome-order:amount', (data) => {
      playNotificationSound();
      dispatch(updateOutcomeCount(data));
    });

    socket.on('error', (error) => {
      notification.error({
        description: error?.message || '',
        message: t('Error')
      });
    });

    socketRef.current = socket;

    return () => {
      socket.disconnect();
      socketRef.current = null;
    };
  }, [token]);

  return (
    <WebSocketContext.Provider
      value={{ isMuted, setIsMuted, socket: socketRef.current }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;

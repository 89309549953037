const defaultState = {
  count: 0,
  data: [],
  isLoading: true
};

export const initialStore = {
  agentsPage: defaultState,
  banksPage: defaultState,
  cardsPage: defaultState,
  clientsPage: defaultState,
  correctionsPage: defaultState,
  debtorsPage: defaultState,
  devicesPage: defaultState,
  holdersPage: defaultState,
  incomesPage: { ...defaultState, countNew: 0 },
  notificationsPage: defaultState,
  operatorsPage: defaultState,
  outcomesPage: {
    ...defaultState,
    countNew: 0
  },
  stats: {
    data: {
      cardsInfo: {
        activeCardsBalance: 0,
        activeOperatorsCardsBalance: 0
      },
      income: {
        amount: 0,
        operations: 0
      },
      outcome: {
        amount: 0,
        operations: 0
      }
    }
  },
  tariffsPage: defaultState,
  transactionsPage: defaultState,
  userData: {
    countIncomes: 0,
    countOutcomes: 0,
    isLoading: true,
    user: null
  },
  usersPage: defaultState
};

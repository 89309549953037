import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import StatsService from '#services/StatsService';
import { updateStats } from '#store/stats';
import { balanceFormatter } from '#utils/balance-formatter';

import classes from './Stats.module.scss';

export const Stats = () => {
  const { t } = useTranslation();
  const statsInfo = useSelector((state) => state.stats.data);
  const { user } = useSelector((state) => state.userData);

  const dispatch = useDispatch();

  const isAdmin = user.role === 'admin';
  const isOperator = user.operator?.id;

  useEffect(() => {
    if (!isOperator) return;

    const getStatsInfo = async () => {
      try {
        const data = await StatsService.getStats();
        dispatch(updateStats(data));
      } catch (e) {
        console.log(e);
      }
    };

    getStatsInfo();
  }, []);

  return statsInfo ? (
    <div className={classes.statsContainer}>
      <div className={classes.statsCard}>
        <div>{t('Receipts')}</div>
        <div>
          {balanceFormatter(statsInfo?.income?.amount)} UAH |{' '}
          {statsInfo.income.operations}
        </div>
      </div>
      <div className={classes.statsCard}>
        <div>{t('Payouts')}</div>
        <div>
          {balanceFormatter(statsInfo?.outcome?.amount)} UAH |{' '}
          {statsInfo.outcome.operations}
        </div>
      </div>
      <div className={classes.statsCard}>
        <div>{t('Balance')}</div>
        <div>
          {balanceFormatter(statsInfo?.cardsInfo?.activeCardsBalance)} UAH
        </div>
      </div>
      {isAdmin && (
        <div className={classes.statsCard}>
          <div>
            {t('Balance')} {isAdmin && '(' + t('active') + ')'}
          </div>
          <div>
            {balanceFormatter(
              statsInfo?.cardsInfo?.activeOperatorsCardsBalance
            )}{' '}
            UAH
          </div>
        </div>
      )}
    </div>
  ) : null;
};
